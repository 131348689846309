import React from 'react';
import  { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import styles from './FeaturesSection.module.css'; 

const FeaturesSection = () => {
  const navigate = useNavigate();

  // Define your cards with titles, subtitles, and onClick handlers
  const cards = [
    {
      title: "Initial Configuration",
      subtitles: [
        { text: "Overview", url: '/docs/initial-config/overview' },
        { text: "Detail Section", url: '/docs/initial-config/details' },
        { text: "Company Info", url: '/docs/initial-config/company-info' },
        { text: "View More...", url: '/docs/initial-config' }
      ]
    },
    {
      title: "Client Profile",
      subtitles: [
        { text: "Client Profile Page", url: '/docs/client-profile/client-profile-page' },
        { text: "Action Buttons", url: '/docs/client-profile/action-buttons' },
        { text: "Navigation Tabs", url: '/docs/client-profile/nav-tabs' }
      ]
    },
    {
      title: "Front Desk",
      subtitles: [
        { text: "Overview", url: '/docs/front-desk/overview' },
        { text: "Header Section", url: '/docs/front-desk/header-section' },
        { text: "Sidebar Navigation Menu", url: '/docs/front-desk/sidebar-navigation-menu' },
        { text: "View More...", url: '/docs/front-desk' }
      ]
    },
    {
      title: "Dashboard",
      subtitles: [
        { text: "Overview", url: '/docs/dashboard/overview' },
        { text: "Pie Chart and Key Metrics", url: '/docs/dashboard/pie-chart-and-key-metrics' },
        { text: "Bar Graph and Summary Statistics", url: '/docs/dashboard/bar-graph-and-summary-statistics' }
      ]
    },
    {
      title: "Subscription",
      subtitles: [
        { text: "Overview", url: '/docs/subscription/overview' },
        { text: "Graph Explanation", url: '/docs/subscription/graph-explanation' },
        { text: "Table Explanation", url: '/docs/subscription/table-explanation' }
      ]
    },
    {
      title: "Client Management",
      subtitles: [
        { text: "Overview", url: '/docs/client-management/overview' },
        { text: "Adding a New Client", url: '/docs/client-management/add-new-client' },
        { text: "Managing Clients", url: '/docs/client-management/managing-clients' },
        { text: "View More...", url: '/docs/client-management' }
      ]
    },
    {
      title: "Drug Test",
      subtitles: [
        { text: "Overview", url: '/docs/drug-test/overview' },
        { text: "Adding a New Drug Test", url: '/docs/drug-test/add-new-drug-test' }
      ]
    },
    {
      title: "One Session",
      subtitles: [
        { text: "Add Form Based Session", url: '/docs/one-session/form-session' },
        { text: "Add Interactive Session", url: '/docs/one-session/interactive-session' }
      ]
    },
    {
      title: "Schedule",
      subtitles: [
        { text: "Overview", url: '/docs/schedule/overview' },
        { text: "Viewing Schedules", url: '/docs/schedule/viewing-schedules' },
        { text: "Creating a Schedule", url: '/docs/schedule/creating-schedule' },
        { text: "View More...", url: '/docs/schedule' }
      ]
    },
    {
      title: "Treatment Plan",
      subtitles: [
        { text: "Overview", url: '/docs/treatment-plan/overview' },
        { text: "Treatment Plan Types", url: '/docs/treatment-plan/treatment-plan-types' },
        { text: "Advanced Treatment Plan", url: '/docs/treatment-plan/advanced-treatment-plan' },
        { text: "View More...", url: '/docs/treatment-plan' }
      ]
    },
    {
      title: "Check-In",
      subtitles: [
        { text: "Overview", url: '/docs/check-in/overview' },
        { text: "Check-In Details Section", url: '/docs/check-in/check-in-details-section' },
        { text: "Check-In Options", url: '/docs/check-in/check-in-options' },
        { text: "View More...", url: '/docs/check-in' }
      ]
    },
    {
      title: "Billing",
      subtitles: [
        { text: "Overview", url: '/docs/billing/overview' },
        { text: "Step-by-Step Billing Process", url: '/docs/billing/step-by-step-billing-process' },
        { text: "Calculation Steps", url: '/docs/billing/calculation-steps' },
        { text: "View More...", url: '/docs/billing' }
      ]
    },
    {
      title: "Invoice Report",
      subtitles: [
        { text: "Overview", url: '/docs/invoice-report/overview' },
        { text: "Invoice Management", url: '/docs/invoice-report/management' },
        { text: "Actions on Invoices", url: '/docs/invoice-report/action' },
        { text: "View More...", url: '/docs/invoice-report' }
      ]
    },
    {
      title: "Self Check-In",
      subtitles: [
        { text: "Overview", url: '/docs/self-check-in/overview' },
        { text: "Steps for Self Check-In", url: '/docs/self-check-in/steps-for-self-check-in' }
      ]
    },
    {
      title: "Staff Management",
      subtitles: [
        { text: "Overview", url: '/docs/staff-management/overview' },
        { text: "Staff Management Page", url: '/docs/staff-management/staff-management-page' },
        { text: "Staff Details Table Columns", url: '/docs/staff-management/staff-details-table' },
        { text: "View More...", url: '/docs/staff-management' }
      ]
    },
    {
      title: "Program Management",
      subtitles: [
        { text: "Overview", url: '/docs/program-management/overview' },
        { text: "Components", url: '/docs/program-management/components' },
        { text: "Categories", url: '/docs/program-management/categories' },
        { text: "View More...", url: '/docs/program-management' }
      ]
    },
    {
      title: "Referral Management",
      subtitles: [
        { text: "Overview", url: '/docs/refferal-management/overview' },
        { text: "Referral Management Page", url: '/docs/refferal-management/ref-page' },
        { text: "Referral Details Table Columns", url: '/docs/refferal-management/components' },
        { text: "View More...", url: '/docs/refferal-management' }
      ]
    },
    {
      title: "Report Management",
      subtitles: [
        { text: "Overview", url: '/docs/report-management/overview' },
        { text: "Medical Report", url: '/docs/report-management/medical' },
        { text: "General Report", url: '/docs/report-management/general' },
        { text: "View More...", url: '/docs/report-management' }
      ]
    },
    {
      title: "Configuration",
      subtitles: [
        { text: "Overview", url: '/docs/configuration/overview' },
        { text: "System Setting", url: '/docs/configuration/system-setting' },
        { text: "Staff", url: '/docs/configuration/staff' },
        { text: "View More...", url: '/docs/configuration' }
      ]
    },
    {
      title: "Forms Management",
      subtitles: [
        { text: "Overview", url: '/docs/forms-management/overview' },
        { text: "Form Details", url: '/docs/forms-management/form-details' },
        { text: "Adding a New Form", url: '/docs/forms-management/add-new-form' },
        { text: "View More...", url: '/docs/forms-management' }
      ]
    },
    {
      title: "Common Calendar",
      subtitles: [
        { text: "Overview", url: '/docs/common-calendar/overview' },
        { text: "Viewing the Common Calendar", url: '/docs/common-calendar/view-common-calendar' },
        { text: "Creating a Schedule in the Common Calendar", url: '/docs/common-calendar/create-schedule-in-common-calendar' },
        { text: "View More...", url: '/docs/common-calendar' }
      ]
    },
    {
      title: "Client Login-Client Tasks",
      subtitles: [
        { text: "Login Process", url: '/docs/client-tasks/login-process' },
        { text: "Introduction", url: '/docs/client-tasks/introduction' },
        { text: "Profile Information", url: '/docs/client-tasks/profile-info' },
        { text: "View More...", url: '/docs/client-tasks' }
      ]
    },
    {
      title: "Referral Login",
      subtitles: [
        { text: "Login Process", url: '/docs/referral-tasks/login-process' },
        { text: "Introduction", url: '/docs/referral-tasks/introduction' },
        { text: "Referral Manager Interface", url: '/docs/referral-tasks/ref-manager-interface' },
        { text: "View More...", url: '/docs/referral-tasks' }
      ]
    },
  ];
  const getColumnCount = () => {
    const width = window.innerWidth;
    if (width >= 992 && width < 1200) {
      return 3; // For screens between 992px and 1200px
    }
    return 4; // Default number of columns
  };
  
  const [columns, setColumns] = React.useState(getColumnCount);
  
  React.useEffect(() => {
    const handleResize = () => setColumns(getColumnCount);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // const [imageUrl, setImageUrl] = useState("");

  // useEffect(() => {
  //   // Fetch signed URL from your backend
  //   const apiUrl =  process.env.REACT_APP_API_URL;
  //   fetch(`${apiUrl}/api/fetch_document_image`)
  //     .then((response) => response.json())
  //     .then((data) => setImageUrl(data.url))
  //     .catch((error) => console.error("Error fetching the signed URL", error));
  // }, []);

  return (
    <div>

<div className="page-slider margin-bottom-20">
    <div id="carousel-example-generic" className="carousel slide carousel-slider">
        <div className="carousel-inner" role="listbox">
            <div className="item carousel-item-document active" style={{ backgroundImage: `url('https://entryplus-site.s3.amazonaws.com/img/Document.png')` }}>
                <div className="container">
                    <div className="carousel-position-six text-uppercase" >
                        <h2 className="margin-bottom-20 animate-delay carousel-title-v5" data-animation="animated fadeInDown">
                        Documents
                        </h2>
                        <p className="carousel-subtitle-v5 border-top-bottom margin-bottom-30" data-animation="animated fadeInDown">
                        Detailed Documentation to Help You Navigate Our Project</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        <div className="container g-padding-y-80--xs g-padding-y-125--sm">

          {/* Render each row of cards */}
          {chunkArray(cards, columns).map((row, rowIndex) => (
            <div key={rowIndex} className="row">
              {row.map((card, cardIndex) => (
                <div key={cardIndex} className="col-sm-6 col-md-4 col-lg-3 g-margin-b-30--xs g-margin-t-30--xs g-margin-b-0--md" data-wow-delay=".2s">
                  <Card
                    title={card.title}
                    subtitles={card.subtitles}
                    onClick={card.onClick}
                    navigate={navigate}
                  />
                </div>
              ))}
            </div>
          ))}

        </div>
      <Footer />
    </div>
  );
};

const Card = ({ title, subtitles, onClick, navigate }) => {
  const handleSubtitleClick = (url) => {
    // Clear localStorage
    localStorage.clear();
    // Navigate to the subtitle URL
    navigate(url);
  };

  return (
  <article>
  <div className="pricing pricing-active hover-effect" style={{ height: '300px' }}>
    <div className="blog-box-text pricing-head pricing-head-active">
      <h5 style={{ color: 'white' }}>{title}</h5>
      <br />
      {/* Render subtitles if available */}
      {subtitles && subtitles.map((subtitle, index) => (
        <div key={index} style={{ paddingBottom: '1px' }}>
          <h6
            onClick={() => handleSubtitleClick(subtitle.url)}
            className={`${styles.subtitle} g-font-size-26--xs g-color--primary`}
            id={`header-${index}`}
            style={{ cursor: 'pointer' }}
          >
            {subtitle.text}
          </h6>
        </div>
      ))}
    </div>
  </div>
 </article>

  );
};

// Helper function to chunk array into rows of cards
function chunkArray(arr, size) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
    arr.slice(index * size, index * size + size)
  );
}

export default FeaturesSection;
