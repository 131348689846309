
// import React, { useState, useEffect, useRef } from 'react';
// import useDocument from '../hooks/useDocument';
// import ImageModal from './ImageModal';
// import './DocumentReader.css';

// const DocumentDisplay = ({ fileKey, onHeadersExtracted, scrollToHeader }) => {
//   const { document, error, loading } = useDocument(fileKey);
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [modalImageSrc, setModalImageSrc] = useState(null);
//   const documentRef = useRef(null);

//   const handleImageClick = (src) => {
//     setModalImageSrc(src);
//     setModalOpen(true);
//   };

//   const handleModalClose = () => {
//     setModalOpen(false);
//     setModalImageSrc(null);
//   };

//   useEffect(() => {
//     if (documentRef.current) {
//       const images = documentRef.current.querySelectorAll('img');
//       images.forEach(img => {
//         img.classList.add('document-image');
//         img.addEventListener('click', () => handleImageClick(img.src));
//       });

//       const headers = documentRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6');
//       const headerValues = Array.from(headers).map((header, index) => {
//         header.id = `header-${index}`;
//         return header.textContent;
//       });

//       if (onHeadersExtracted) {
//         onHeadersExtracted(headerValues);
//       }

//       return () => {
//         images.forEach(img => {
//           img.classList.remove('document-image');
//           img.removeEventListener('click', () => handleImageClick(img.src));
//         });
//       };
//     }
//   }, [document]);

//   useEffect(() => {
//     if (scrollToHeader && documentRef.current) {
//       const headerElement = documentRef.current.querySelector(`#${scrollToHeader}`);
//       if (headerElement) {
//         headerElement.scrollIntoView({ behavior: 'smooth' });
//       }
//     }
//   }, [scrollToHeader]);

//   return (
//     <div className="doc-container">
//       {loading && <p>Loading...</p>}
//       {error && <p style={{ color: 'red' }}>{error}</p>}
//       {document && (
//         <div
//           ref={documentRef}
//           className="document-content"
//           dangerouslySetInnerHTML={{
//             __html: document.content,
//           }}
//         />
//       )}
//       <ImageModal isOpen={isModalOpen} imageSrc={modalImageSrc} onClose={handleModalClose} />
//     </div>
//   );
// };

// export default DocumentDisplay;



import React, { useState, useEffect, useRef } from 'react'; 
import useDocument from '../hooks/useDocument';
import ImageModal from './ImageModal';
import './DocumentReader.css';

const DocumentDisplay = ({ fileKey, onHeadersExtracted, scrollToHeader, onHeaderChange }) => {
  const { document, error, loading } = useDocument(fileKey);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState(null);
  const documentRef = useRef(null);
  const headersRef = useRef([]); // To store the header elements

  const handleImageClick = (src) => {
    setModalImageSrc(src);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalImageSrc(null);
  };

  useEffect(() => {
    if (documentRef.current) {
      const images = documentRef.current.querySelectorAll('img');
      images.forEach(img => {
        img.classList.add('document-image');
        img.addEventListener('click', () => handleImageClick(img.src));
      });

      // const headers = documentRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6');
      // headersRef.current = headers; // Store the headers in a ref for later use
      // const headerValues = Array.from(headers).map((header, index) => {
      //   header.id = `header-${index}`; // Set header ID
      //   return header.textContent;
      // });

      const headers = documentRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6');
      headersRef.current = headers; // Store the headers in a ref for later use

      const headerValues = Array.from(headers).map((header, index) => {
        // Logic to remove certain headers based on multiple fileKeys
        let shouldRemoveHeader = false;

        // Conditions for different fileKeys
        const removalIndicesMap = {
                    'Initial Configuration.docx': [13, 14, 15, 16, 17, 18, 19, 20, 21],
                    'Client Profile Page.docx': [2, 3, 4, ...Array.from({ length: 21 }, (_, i) => i + 6)],
                    'Subscription.docx': [2, 4, 5],
                    'Client Management.docx': [3, 4, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20, 21],
                    'Schedule.docx': [2, 3, 6, 7, 8, 9, 10, 11],
                    'Treatment Plan.docx': [3, 4, 5, 7, 9, 10, 11, 13, 15, 16, 17, 19, 21, 22, 23, 25],
                    'Billing.docx': [4, 6],
                    'Invoice Report.docx': [5, 7],
                    'Self Check-In.docx': [2, 3, 4, 5],
                    'Staff Management.docx': [2],
                    'Program Management.docx': [1, 4, 5],
                    'Report Management.docx': [2, 4, 6, 8, 10, 12, 14, 16, 18, 19, 21, 23, 24, 25, 27, 28, 30, 31, 32, 34, 36, 38, 39, 40],
                    'Configuration.docx': [1, 3, 4, 6, 7, 8, 10, 11, 12, 13, 15, 16, 18, 19, 21, 22, 24, 26, 27, 29, 30, 32, 33, 34, 36, 37, 39, 41, 42, 43, 45, 47, 48, 49],
                    'Forms Management.docx': [2, 3],
                    'Common Calendar.docx': [2, 3, 5],
                    'Client Login-Client Tasks.docx': [1, 4, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21],
                    'Referral Login-Referral Tasks.docx': [1, 4, 5, 6, 7, 9, 10],
                  };
                  
                   shouldRemoveHeader = removalIndicesMap[fileKey]?.includes(index) || false;

        if (!shouldRemoveHeader) {
          header.id = `header-${index}`; // Set header ID only if it shouldn't be removed
          return header.textContent;
        } else {
          return null; // Skip adding this header
        }
      }).filter(Boolean); // Remove null values from the array


      if (onHeadersExtracted) {
        onHeadersExtracted(headerValues);
      }

      return () => {
        images.forEach(img => {
          img.classList.remove('document-image');
          img.removeEventListener('click', () => handleImageClick(img.src));
        });
      };
    }
  }, [document]);

  useEffect(() => {
    if (scrollToHeader && documentRef.current) {
      const headerElement = documentRef.current.querySelector(`#${scrollToHeader}`);
      if (headerElement) {
        headerElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [scrollToHeader]);

  // Log current header ID when scrolling
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = documentRef.current.scrollTop;
      let currentHeaderId = null;
    
      headersRef.current.forEach(header => {
        const rect = header.getBoundingClientRect();
        const containerRect = documentRef.current.getBoundingClientRect();
        
        // Adjusting the container's top position by adding 5 pixels for padding
        const adjustedContainerTop = containerRect.top + 5;
      
        // Check if the header is within the visible area of the container with padding
        if (rect.top <= adjustedContainerTop && rect.bottom >= adjustedContainerTop) {
          currentHeaderId = header.id; // Get the ID of the currently visible header
        }
      });
      
    
      // console.log('Detected currentHeaderId:', currentHeaderId);
      if (currentHeaderId) {
        // console.log('Current header ID:', currentHeaderId);
        if (onHeaderChange) {
        console.log('Current header ID:', currentHeaderId);

          onHeaderChange(currentHeaderId); // Call the prop function with the current header ID
        }
      }
    };
    
    

    const docContainer = documentRef.current;

    if (docContainer) {
      docContainer.addEventListener('scroll', handleScroll); // Attach the scroll event listener
    }

    return () => {
      if (docContainer) {
        docContainer.removeEventListener('scroll', handleScroll); // Cleanup on component unmount
      }
    };
  }, [documentRef.current, onHeaderChange]); // Add onHeaderChange as a dependency

  return (
    <div className="doc-container">
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {document && (
        <div
          ref={documentRef}
          className="document-content"
          style={{ overflowY: 'auto', maxHeight: '700px' }} // Adjust the height as needed
          dangerouslySetInnerHTML={{
            __html: document.content,
          }}
        />
      )}
      <ImageModal isOpen={isModalOpen} imageSrc={modalImageSrc} onClose={handleModalClose} />
    </div>
  );
};

export default DocumentDisplay;

// import React, { useState, useEffect, useRef } from 'react'; 
// import ImageModal from './ImageModal';
// import './DocumentReader.css';

// const DocumentDisplay = ({ fileKey, onHeadersExtracted, scrollToHeader, onHeaderChange }) => {
//   const [documentContent, setDocumentContent] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [modalImageSrc, setModalImageSrc] = useState(null);
//   const documentRef = useRef(null);
//   const headersRef = useRef([]);

//   const fetchDocument = async (fileKey) => {
//     const apiUrl =  process.env.REACT_APP_API_URL;
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/document/${fileKey}`); // Replace with your API endpoint
//       if (!response.ok) {
//         throw new Error('Failed to fetch document');
//       }
//       const data = await response.text(); // Fetch the response as text (HTML)
//       setDocumentContent(data); // Set the fetched HTML content
//       setError(null);
//     } catch (error) {
//       setError(error.message);
//       setDocumentContent(null);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchDocument(fileKey);
//   }, [fileKey]);

//   const handleImageClick = (src) => {
//     setModalImageSrc(src);
//     setModalOpen(true);
//   };

//   const handleModalClose = () => {
//     setModalOpen(false);
//     setModalImageSrc(null);
//   };

//   useEffect(() => {
//     if (documentRef.current && documentContent) {
//       const images = documentRef.current.querySelectorAll('img');
//       images.forEach(img => {
//         img.classList.add('document-image');
//         img.addEventListener('click', () => handleImageClick(img.src));
//       });

//       const headers = documentRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6');
//       headersRef.current = headers;

//       const headerValues = Array.from(headers).map((header, index) => {
//         let shouldRemoveHeader = false;

//         const removalIndicesMap = {
//           'Initial Configuration.docx': [13, 14, 15, 16, 17, 18, 19, 20, 21],
//           'Client Profile Page.docx': [2, 3, 4, ...Array.from({ length: 21 }, (_, i) => i + 6)],
//           'Subscription.docx': [2, 4, 5],
//           'Client Management.docx': [3, 4, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20, 21],
//           'Schedule.docx': [2, 3, 6, 7, 8, 9, 10, 11],
//           'Treatment Plan.docx': [3, 4, 5, 7, 9, 10, 11, 13, 15, 16, 17, 19, 21, 22, 23, 25],
//           'Billing.docx': [4, 6],
//           'Invoice Report.docx': [5, 7],
//           'Self Check-In.docx': [2, 3, 4, 5],
//           'Staff Management.docx': [2],
//           'Program Management.docx': [1, 4, 5],
//           'Report Management.docx': [2, 4, 6, 8, 10, 12, 14, 16, 18, 19, 21, 23, 24, 25, 27, 28, 30, 31, 32, 34, 36, 38, 39, 40],
//           'Configuration.docx': [1, 3, 4, 6, 7, 8, 10, 11, 12, 13, 15, 16, 18, 19, 21, 22, 24, 26, 27, 29, 30, 32, 33, 34, 36, 37, 39, 41, 42, 43, 45, 47, 48, 49],
//           'Forms Management.docx': [2, 3],
//           'Common Calendar.docx': [2, 3, 5],
//           'Client Login-Client Tasks.docx': [1, 4, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21],
//           'Referral Login-Referral Tasks.docx': [1, 4, 5, 6, 7, 9, 10],
//         };
        
//          shouldRemoveHeader = removalIndicesMap[fileKey]?.includes(index) || false;
        
//         // Your existing logic to set shouldRemoveHeader based on fileKey...
//         // (omitted for brevity, keep it as is)

//         if (!shouldRemoveHeader) {
//           header.id = `header-${index}`; // Set header ID only if it shouldn't be removed
//           return header.textContent;
//         } else {
//           return null; // Skip adding this header
//         }
//       }).filter(Boolean);

//       if (onHeadersExtracted) {
//         onHeadersExtracted(headerValues);
//       }

//       return () => {
//         images.forEach(img => {
//           img.classList.remove('document-image');
//           img.removeEventListener('click', () => handleImageClick(img.src));
//         });
//       };
//     }
//   }, [documentContent]);

//   useEffect(() => {
//     if (scrollToHeader && documentRef.current) {
//       const headerElement = documentRef.current.querySelector(`#${scrollToHeader}`);
//       if (headerElement) {
//         headerElement.scrollIntoView({ behavior: 'smooth' });
//       }
//     }
//   }, [scrollToHeader]);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = documentRef.current.scrollTop;
//       let currentHeaderId = null;
    
//       headersRef.current.forEach(header => {
//         const rect = header.getBoundingClientRect();
//         const containerRect = documentRef.current.getBoundingClientRect();
//         const adjustedContainerTop = containerRect.top + 5;
        
//         if (rect.top <= adjustedContainerTop && rect.bottom >= adjustedContainerTop) {
//           currentHeaderId = header.id; 
//         }
//       });

//       if (currentHeaderId && onHeaderChange) {
//         console.log('Current header ID:', currentHeaderId);
//         onHeaderChange(currentHeaderId);
//       }
//     };

//     const docContainer = documentRef.current;

//     if (docContainer) {
//       docContainer.addEventListener('scroll', handleScroll);
//     }

//     return () => {
//       if (docContainer) {
//         docContainer.removeEventListener('scroll', handleScroll);
//       }
//     };
//   }, [documentRef.current, onHeaderChange]);

//   return (
//     <div className="doc-container">
//       {loading && <p>Loading...</p>}
//       {error && <p style={{ color: 'red' }}>{error}</p>}
//       {documentContent && (
//         <div
//           ref={documentRef}
//           className="document-content"
//           style={{ overflowY: 'auto', maxHeight: '700px' }}
//           dangerouslySetInnerHTML={{
//             __html: documentContent, // Render the fetched HTML content
//           }}
//         />
//       )}
//       <ImageModal isOpen={isModalOpen} imageSrc={modalImageSrc} onClose={handleModalClose} />
//     </div>
//   );
// };

// export default DocumentDisplay;

