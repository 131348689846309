import { useEffect, useState } from 'react';

function Favicon() {
  const [imageUrl, setImageUrl] = useState("");

  // useEffect(() => {
  //   // Fetch signed URL from your backend
  //   const apiUrl =  process.env.REACT_APP_API_URL;
  //   fetch(`${apiUrl}/api/fetch_favicon_image`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setImageUrl(data.url); // Update state with the signed URL
  //       const favicon = document.getElementById('favicon');
  //       if (favicon) {
  //         favicon.href = data.url; // Dynamically update the favicon href
  //       }
  //     })
  //     .catch((error) => console.error("Error fetching the signed URL", error));
  // }, []);

  return null; // No content needed, just the favicon update
}

export default Favicon;
