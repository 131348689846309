// import React, { useState } from 'react';
// import ScrollPanel from './ScrollPanel';
// import DocumentDisplay from './DocumentDisplay';
// import './SessionInfo.css';

// const InfoPage = ({ fileKey }) => {
//   const [scrollToHeader, setScrollToHeader] = useState(null);

//   const handleHeaderClick = (headerId) => {
//     setScrollToHeader(headerId);
//   };

//   return (
//     <div className="session-info-container">
//       <div className="content">
//         <ScrollPanel onHeaderClick={handleHeaderClick} />
//         <div className="main-content">
//           <DocumentDisplay fileKey={fileKey} scrollToHeader={scrollToHeader} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default InfoPage;


import React, { useState } from 'react';
import ScrollPanel from './ScrollPanel';
import DocumentDisplay from './DocumentDisplay';
import './SessionInfo.css';

const InfoPage = ({ fileKey }) => {
  const [scrollToHeader, setScrollToHeader] = useState(null);
  const [currentHeaderId, setCurrentHeaderId] = useState(null); // Track the current header ID

  const handleHeaderClick = (headerId) => {
    setScrollToHeader(headerId);
  };

  // This function will be passed to DocumentDisplay
  const onHeaderChange = (headerId) => {
    setCurrentHeaderId(headerId); // Update current header ID
  };

  return (
    <div className="session-info-container">
      <div className="content">
        <ScrollPanel 
          onHeaderClick={handleHeaderClick} 
          currentHeaderId={currentHeaderId} // Pass the current header ID to ScrollPanel
        />
        <div className="main-content">
          <DocumentDisplay 
            fileKey={fileKey} 
            scrollToHeader={scrollToHeader} 
            onHeaderChange={onHeaderChange} 
          />
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
