import React, { useState, useEffect } from 'react';
import './Header.css';

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  // const [imageUrl, setImageUrl] = useState("");

  // useEffect(() => {
  //   // Fetch signed URL from your backend
  //   const apiUrl =  process.env.REACT_APP_API_URL;
  //   fetch(`${apiUrl}/api/fetch_logo`)
  //     .then((response) => response.json())
  //     .then((data) => setImageUrl(data.url))
  //     .catch((error) => console.error("Error fetching the signed URL", error));
  // }, []);

  useEffect(() => {
    // Function to handle clicks outside the header and mobi-toggler
    const handleClickOutside = (event) => {
      if (!event.target.closest('.header') && !event.target.closest('.mobi-toggler')) {
        if (menuOpened) {
          setMenuOpened(false);
        }
      }
    };

    // Add event listener for document-wide clicks
    document.addEventListener('click', handleClickOutside);

    // Cleanup event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menuOpened]);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpened(!menuOpened);
  };

  return (
    <div className="corporate page-header-fixed">
      <div className={`header ${menuOpened ? 'menuOpened' : ''}`}>
        <div className="container">
          <a className="site-logo" href="index.php">
            <img className='site-logo-image' src='https://entryplus-site.s3.amazonaws.com/img/logos/entryplus_logo.png' alt="Entryplus Logo" />
          </a>

          <button className="mobi-toggler" onClick={toggleMenu}>
            <i className="fa fa-bars"></i>
          </button>

          <div
            className="header-navigation pull-right font-transform-inherit"
            style={{
              display: menuOpened ? 'block' : 'none',
              transition: 'display 300ms ease-in-out',
            }}
          >
            <ul>
              <li className="dropdown">
                <a href="/index.php">Home</a>
              </li>
              <li className="dropdown">
                <a href="/about_us.php">About Us</a>
              </li>
              <li className="dropdown">
                <a
                  className="dropdown-toggle"
                  onClick={(e) => e.preventDefault()}
                  data-toggle="dropdown"
                  href="#"
                >
                  Features
                </a>
                <ul className="dropdown-menu">
                  <li id="comprehensive_dashboard">
                    <a href="/comprehensive_dashboard.php">Comprehensive Dashboard</a>
                  </li>
                  <li id="client_pre_registration">
                    <a href="/client_pre_registration.php">Client Pre-Registration</a>
                  </li>
                  <li id="drugtest_management">
                    <a href="/drugtest_management.php">Drugtest Management</a>
                  </li>
                  <li id="check_in_management">
                    <a href="/check_in_management.php">Check-in Management</a>
                  </li>
                  <li id="staff_management">
                    <a href="/staff_management.php">Staff Management</a>
                  </li>
                  <li id="schedule_management">
                    <a href="/schedule_management.php">Schedule Management</a>
                  </li>
                  <li id="therapy_session">
                    <a href="/therapy_session.php">Therapy Session</a>
                  </li>
                  <li id="treatment_plan">
                    <a href="/treatment_plan.php">Treatment Plan</a>
                  </li>
                  <li id="billing_management">
                    <a href="/billing_management.php">Billing Management</a>
                  </li>
                  <li id="self_check_in">
                    <a href="/self_check_in.php">Self Check-in</a>
                  </li>
                  <li id="referral_management">
                    <a href="/referral_management.php">Referral Management</a>
                  </li>
                  <li id="program_management">
                    <a href="/program_management.php">Program Management</a>
                  </li>
                  <li id="configuration_management">
                    <a href="/configuration_management.php">Configuration Management</a>
                  </li>
                  <li id="client_management">
                    <a href="/client_management.php">Client Management</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown active">
                <a
                  href="/docs"
                >
                  Docs
                </a>
              </li>
              <li className="dropdown">
                <a href="/blogs.php">Blog</a>
              </li>
              <li className="dropdown">
                <a href="/career.php">Career</a>
              </li>
              <li className="dropdown">
                <a href="/pricing_plan.php">Pricing Plan</a>
              </li>
              <li className="demo-button">
                <a
                  href="/free_demo_enquires.php"
                  className="btn btn-primary free-demo-enquiry"
                  
                >
                  Free Demo & Enquiries
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
