// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
// import './ScrollPanel.css';

// const ScrollPanel = ({ onHeaderClick }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();
  
//   const togglePanel = () => {
//     setIsOpen(!isOpen);
//   };

//   const toggleSubMenu = (index) => {
//     const updatedMenuItems = menuItems.map((item, i) => {
//       if (i === index) {
//         const updatedItem = { ...item, isSubMenuOpen: !item.isSubMenuOpen };
//         localStorage.setItem(`submenu-open-${i}`, updatedItem.isSubMenuOpen);
//         return updatedItem;
//       } else {
//         localStorage.setItem(`submenu-open-${i}`, false);
//         return { ...item, isSubMenuOpen: false };
//       }
//     });
//     setMenuItems(updatedMenuItems);
//   };

//   const handleHeaderClick = (headerId, path) => {
//     if (onHeaderClick) {
//       onHeaderClick(headerId);
//     }
//     navigate(path);
//   };

//   const handleItemClick = (item, index) => {
//     localStorage.clear();
//     toggleSubMenu(index);

//     if (!item.subItems) {
//       console.log('Navigating to:', item.path);
//       navigate(item.path);
//     }
//   };

//   const handleInitialConfigurationClick = (item, index) => {
//     localStorage.clear();
//     console.log('Navigating to Initial Configuration:', item.path);
//     toggleSubMenu(index);
//     navigate(item.path);
//   };

//   const [menuItems, setMenuItems] = useState([
//     {
//       path: '/docs/initial-config',
//       label: 'Initial Configuration',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/initial-config/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/initial-config/details', label: 'Details Section', headerId: 'header-1' },
//         { path: '/docs/initial-config/company-info', label: 'Company Info', headerId: 'header-2' },
//         { path: '/docs/initial-config/policy-role', label: 'Staff Policy and Role', headerId: 'header-3' },
//         { path: '/docs/initial-config/session-location', label: 'Session Location and Type', headerId: 'header-4' },
//         { path: '/docs/initial-config/bill-due', label: 'Bill Due Date and CPT Code Cost', headerId: 'header-5' },
//         { path: '/docs/initial-config/drugtest-type', label: 'Drugtest Type and Method', headerId: 'header-6' },
//         { path: '/docs/initial-config/form-group', label: 'Form Group Creation', headerId: 'header-7' },
//         { path: '/docs/initial-config/event-type', label: 'Event Type in Scheduling Config', headerId: 'header-8' },
//         { path: '/docs/initial-config/program-care', label: 'Program and Care Type', headerId: 'header-9' },
//         { path: '/docs/initial-config/check-in-date', label: 'Check-in Date Flag', headerId: 'header-10' },
//         { path: '/docs/initial-config/ref-source', label: 'Referral Source', headerId: 'header-11' },
//         { path: '/docs/initial-config/payment-insurance', label: 'Payment and Insurance type', headerId: 'header-12' },
//         { path: '/docs/initial-config/medication', label: 'Medication Type', headerId: 'header-22' },
//         { path: '/docs/initial-config/new-staff', label: 'Create New Staff', headerId: 'header-23' },
//         { path: '/docs/initial-config/contact-role', label: 'Staff Contact & Role', headerId: 'header-24' },
//       ]
//     },
//     {
//       path: '/docs/front-desk',
//       label: 'Front Desk',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/front-desk/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/front-desk/header-section', label: 'Header Section', headerId: 'header-1' },
//         { path: '/docs/front-desk/sidebar-navigation-menu', label: 'Sidebar Navigation Menu', headerId: 'header-2' },
//         { path: '/docs/front-desk/main-content-area', label: 'Main Content Area', headerId: 'header-3' },
//         { path: '/docs/front-desk/favorites-section', label: 'Favorites Section', headerId: 'header-4' },
//         { path: '/docs/front-desk/user-profile-tab', label: 'User Profile Tab', headerId: 'header-5' },
//         { path: '/docs/front-desk/floating-menu', label: 'Floating Menu', headerId: 'header-6' },
//         { path: '/docs/front-desk/chat-window', label: 'Chat Window (Entryplus Intellibot Beta)', headerId: 'header-7' },
//       ]
//     },
//     {
//       path: '/docs/dashboard',
//       label: 'Dashboard',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/dashboard/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/dashboard/pie-chart-and-key-metrics', label: 'Pie Chart and Key Metrics', headerId: 'header-1' },
//         { path: '/docs/dashboard/bar-graph-and-summary-statistics', label: 'Bar Graph and Summary Statistics', headerId: 'header-2' },
//       ]
//     },
//     {
//       path: '/docs/subscription',
//       label: 'Subscription',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/subscription/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/subscription/graph-explanation', label: 'Graph Explanation', headerId: 'header-1' },
//         { path: '/docs/subscription/table-explanation', label: 'Table Explanation', headerId: 'header-3' },
//       ]
//     },
//     {
//       path: '/docs/client-management',
//       label: 'Client Management',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/client-management/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/client-management/add-new-client', label: 'Adding a New Client', headerId: 'header-1' },
//         { path: '/docs/client-management/managing-clients', label: 'Managing Clients', headerId: 'header-2' },
//         { path: '/docs/client-management/detailed-tab-description', label: 'Detailed Tabs Description', headerId: 'header-5' },
//         { path: '/docs/client-management/pre-registration-process', label: 'Client Pre-Registration Process', headerId: 'header-13' },
//         { path: '/docs/client-management/client-status-change', label: 'Client Status Change', headerId: 'header-22' },
//         { path: '/docs/client-management/assigning-staff', label: 'Assigning staff for a client', headerId: 'header-23' },
//         { path: '/docs/client-management/add-programs', label: 'Add programs for a client', headerId: 'header-24' },
//         { path: '/docs/client-management/change-level-of-care', label: 'Change the Level of Care', headerId: 'header-25' }
//       ]
//     },
//     {
//       path: '/docs/drug-test',
//       label: 'Drug Test',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/drug-test/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/drug-test/add-new-drug-test', label: 'Adding a New Drug Test', headerId: 'header-1' }
//       ]
//     },
//     {
//       path: '/docs/one-session',
//       label: 'One Session',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/one-session/form-session', label: 'Add Form Based Session', headerId: 'header-0' },
//         { path: '/docs/one-session/interactive-session', label: 'Add Interactive Session', headerId: 'header-1' },
//       ]
//     },
//     {
//       path: '/docs/schedule',
//       label: 'Schedule',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/schedule/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/schedule/viewing-schedules', label: 'Viewing Schedules', headerId: 'header-1' },
//         { path: '/docs/schedule/creating-schedule', label: 'Creating a Schedule', headerId: 'header-4' },
//         { path: '/docs/schedule/type-of-schedules', label: 'Types of Schedules', headerId: 'header-5' },
//         { path: '/docs/schedule/appointment-status-update-for-client', label: 'Appointment Status Update for Client', headerId: 'header-10' },
//         { path: '/docs/schedule/sharing-calendar', label: 'Sharing Calendar', headerId: 'header-11' },
//         { path: '/docs/schedule/viewing-calendar-invites', label: 'Viewing Calendar Invites', headerId: 'header-12' },
//         { path: "/docs/schedule/viewing-today's-schedule", label: "Viewing Today's Schedule", headerId: 'header-13' }
//       ]
//     },
//     {
//       path: '/docs/treatment-plan',
//       label: 'Treatment Plan',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/treatment-plan/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/treatment-plan/treatment-plan-types', label: 'Treatment Plan Types', headerId: 'header-1' },
//         { path: '/docs/treatment-plan/advanced-treatment-plan', label: 'Advanced Treatment Plan', headerId: 'header-2' },
//         { path: '/docs/treatment-plan/add-new-advanced-treatment-plan', label: 'Adding a New Advanced Treatment Plan', headerId: 'header-6' },
//         { path: '/docs/treatment-plan/standard-treatment-plan', label: 'Standard Treatment Plan', headerId: 'header-8' },
//         { path: '/docs/treatment-plan/add-new-standard-treatment-plan', label: 'Adding a New Standard Treatment Plan', headerId: 'header-12' },
//         { path: '/docs/treatment-plan/initial-treatment-plan', label: 'Initial Treatment Plan', headerId: 'header-14' },
//         { path: '/docs/treatment-plan/add-new-initial-treatment-plan', label: 'Adding a New Initial Treatment Plan', headerId: 'header-18' },
//         { path: '/docs/treatment-plan/treatment-plan-by-templates', label: 'Treatment Plan By Templates', headerId: 'header-20' },
//         { path: '/docs/treatment-plan/add-new-treatment-plan-by-templates', label: 'Adding a New Treatment Plan by Template', headerId: 'header-24' }
//       ]
//     },
//     {
//       path: '/docs/check-in',
//       label: 'Check-In',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/check-in/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/check-in/check-in-details-section', label: 'Check-In Details Section', headerId: 'header-1' },
//         { path: '/docs/check-in/check-in-options', label: 'Check-In Options', headerId: 'header-2' },
//         { path: '/docs/check-in/individual-check-in', label: 'Individual Check-In', headerId: 'header-3' },
//         { path: '/docs/check-in/self-check-in', label: 'Self Check-In', headerId: 'header-4' },
//         { path: '/docs/check-in/group-check-in', label: 'Group Check-In', headerId: 'header-5' }
//       ]
//     },
//     {
//       path: '/docs/billing',
//       label: 'Billing',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/billing/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/billing/step-by-step-billing-process', label: 'Step-by-Step Billing Process', headerId: 'header-1' },
//         { path: '/docs/billing/calculation-steps', label: 'Calculation Steps', headerId: 'header-2' },
//         { path: '/docs/billing/example-calculation-1', label: 'Example Calculation 1', headerId: 'header-3' },
//         { path: '/docs/billing/example-calculation-2', label: 'Example Calculation 2', headerId: 'header-5' }
//       ]
//     },
//     {
//       path: '/docs/invoice-report',
//       label: 'Invoice Report',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/invoice-report/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/invoice-report/management', label: 'Invoice Management', headerId: 'header-1' },
//         { path: '/docs/invoice-report/action', label: 'Actions on Invoices', headerId: 'header-2' },
//         { path: '/docs/invoice-report/payment-status', label: 'Changing Payment Status', headerId: 'header-3' },
//         { path: '/docs/invoice-report/advanced-search', label: 'Advanced Search', headerId: 'header-4' },
//         { path: '/docs/invoice-report/add-bill', label: 'Add New Bill', headerId: 'header-6' },
//         { path: '/docs/invoice-report/unarchive', label: 'Unarchive', headerId: 'header-8' }
//       ]
//     },
//     {
//       path: '/docs/self-check-in',
//       label: 'Self Check-In',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/self-check-in/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/self-check-in/steps-for-self-check-in', label: 'Steps for Self Check-In', headerId: 'header-1' }
//       ]
//     },
//     {
//       path: '/docs/staff-management',
//       label: 'Staff Management',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/staff-management/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/staff-management/staff-management-page', label: 'Staff Management Page', headerId: 'header-1' },
//         { path: '/docs/staff-management/staff-details-table', label: 'Staff Details Table Columns', headerId: 'header-3' },
//         { path: '/docs/staff-management/action-icons', label: 'Action Icons in the Tasks Column', headerId: 'header-4' },
//         { path: '/docs/staff-management/staff-status', label: 'Staff Status', headerId: 'header-5' },
//         { path: '/docs/staff-management/add-new-staff', label: 'How to add new staff', headerId: 'header-6' },
//         { path: '/docs/staff-management/staff-info', label: 'Staff Information', headerId: 'header-7' },
//         { path: '/docs/staff-management/staff-history', label: 'Staff History', headerId: 'header-8' },
//         { path: '/docs/staff-management/mfa', label: 'MFA-Multi Factor Authentication', headerId: 'header-9' },
//         { path: '/docs/staff-management/black-boxing-add', label: 'Black Boxing Clients(add)', headerId: 'header-10' },
//         { path: '/docs/staff-management/black-boxing-remove', label: 'Black Boxing Clients(remove)', headerId: 'header-11' }
//       ]
//     },
//     {
//       path: '/docs/program-management',
//       label: 'Program Management',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/program-management/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/program-management/components', label: 'Components', headerId: 'header-2' },
//         { path: '/docs/program-management/categories', label: 'Categories', headerId: 'header-3' },
//         { path: '/docs/program-management/client-info', label: 'Client Info Table', headerId: 'header-6' }
//       ]
//     },
//     {
//       path: '/docs/refferal-management',
//       label: 'Referral Management',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/refferal-management/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/refferal-management/ref-page', label: 'Referral Management Page', headerId: 'header-1' },
//         { path: '/docs/refferal-management/components', label: 'Components', headerId: 'header-2' },
//         { path: '/docs/refferal-management/ref-details', label: 'Referral Details Table Columns', headerId: 'header-3' },
//         { path: '/docs/refferal-management/action-icons', label: 'Action Icons in the Tasks Column', headerId: 'header-4' },
//         { path: '/docs/refferal-management/add-ref', label: 'Add a New Referral', headerId: 'header-5' }
//       ]
//     },
//     {
//       path: '/docs/report-management',
//       label: 'Report Management',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/report-management/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/report-management/medical', label: 'Medical Report', headerId: 'header-1' },
//         { path: '/docs/report-management/general', label: 'General Report', headerId: 'header-3' },
//         { path: '/docs/report-management/referral', label: 'Referral Report', headerId: 'header-5' },
//         { path: '/docs/report-management/attendance', label: 'Attendance Report', headerId: 'header-7' },
//         { path: '/docs/report-management/income', label: 'Income Report', headerId: 'header-9' },
//         { path: '/docs/report-management/appointment', label: 'Appointment Report', headerId: 'header-11' },
//         { path: '/docs/report-management/session', label: 'Session Report', headerId: 'header-13' },
//         { path: '/docs/report-management/outstanding', label: 'Outstanding Report', headerId: 'header-15' },
//         { path: '/docs/report-management/treatment', label: 'Treatment Plan Report', headerId: 'header-17' },
//         { path: '/docs/report-management/client-progress', label: 'Client Progress Report', headerId: 'header-20' },
//         { path: '/docs/report-management/client-status', label: 'Client Status Report', headerId: 'header-22' },
//         { path: '/docs/report-management/absentees', label: 'Absentees Status Report', headerId: 'header-26' },
//         { path: '/docs/report-management/staff-audit', label: 'Staff Audit', headerId: 'header-29' },
//         { path: '/docs/report-management/weekly-business-audit', label: 'Weekly Business Audit', headerId: 'header-33' },
//         { path: '/docs/report-management/weekly-counselor-audit', label: 'Weekly Counselor Audit', headerId: 'header-35' },
//         { path: '/docs/report-management/custom', label: 'Custom Report', headerId: 'header-37' }
//       ]
//     },
//     {
//       path: '/docs/configuration',
//       label: 'Configuration',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/configuration/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/configuration/system-setting', label: 'System Setting', headerId: 'header-2' },
//         { path: '/docs/configuration/staff', label: 'Staff', headerId: 'header-5' },
//         { path: '/docs/configuration/session', label: 'Session', headerId: 'header-9' },
//         { path: '/docs/configuration/interactive-session', label: 'Interactive Session', headerId: 'header-14' },
//         { path: '/docs/configuration/billing', label: 'Billing', headerId: 'header-17' },
//         { path: '/docs/configuration/drug-test', label: 'Drug Test', headerId: 'header-20' },
//         { path: '/docs/configuration/forms', label: 'Forms', headerId: 'header-23' },
//         { path: '/docs/configuration/schedule', label: 'Schedule', headerId: 'header-25' },
//         { path: '/docs/configuration/program', label: 'Program', headerId: 'header-28' },
//         { path: '/docs/configuration/treatment-plan', label: 'Treatment Plan', headerId: 'header-31' },
//         { path: '/docs/configuration/check-in', label: 'Check-In', headerId: 'header-35' },
//         { path: '/docs/configuration/referral', label: 'Referral', headerId: 'header-38' },
//         { path: '/docs/configuration/payment', label: 'Payment', headerId: 'header-40' },
//         { path: '/docs/configuration/medication', label: 'Medication', headerId: 'header-44' },
//         { path: '/docs/configuration/question-bank', label: 'Question Bank', headerId: 'header-46' }
//       ]
//     },
//     {
//       path: '/docs/forms-management',
//       label: 'Forms Management',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/forms-management/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/forms-management/form-details', label: 'Form Details', headerId: 'header-1' },
//         { path: '/docs/forms-management/add-new-form', label: 'Adding a New Form', headerId: 'header-4' },
//         { path: '/docs/forms-management/add-new-group', label: 'Adding a New Group', headerId: 'header-5' }
//       ]
//     },
//     {
//       path: '/docs/common-calendar',
//       label: 'Common Calendar',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/common-calendar/overview', label: 'Overview', headerId: 'header-0' },
//         { path: '/docs/common-calendar/view-common-calendar', label: 'Viewing the Common Calendar', headerId: 'header-1' },
//         { path: '/docs/common-calendar/create-schedule-in-common-calendar', label: 'Creating a Schedule in the Common Calendar', headerId: 'header-4' },
//         { path: '/docs/common-calendar/view-schedule-details', label: 'Viewing Schedule Details', headerId: 'header-6' },
//         { path: '/docs/common-calendar/print-schedule', label: 'Print Schedule', headerId: 'header-7' }
//       ]
//     },
//     {
//       path: '/docs/client-tasks',
//       label: 'Client Login',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/client-tasks/login-process', label: 'Login Process', headerId: 'header-0' },
//         { path: '/docs/client-tasks/introduction', label: 'Introduction', headerId: 'header-2' },
//         { path: '/docs/client-tasks/profile-info', label: 'Profile Information', headerId: 'header-3' },
//         { path: '/docs/client-tasks/form-registration', label: 'Form Registration', headerId: 'header-5' },
//         { path: '/docs/client-tasks/guided-registration', label: 'Guided Registration', headerId: 'header-8' },
//         { path: '/docs/client-tasks/check-in', label: 'Check-in', headerId: 'header-19' },
//         { path: '/docs/client-tasks/assessment', label: 'Assessment', headerId: 'header-22' },
//         { path: '/docs/client-tasks/user-profile', label: 'User Profile', headerId: 'header-23' },
//       ]
//     },
//     {
//       path: '/docs/referral-tasks',
//       label: 'Referral Login',
//       isSubMenuOpen: false,
//       subItems: [
//         { path: '/docs/referral-tasks/login-process', label: 'Login Process', headerId: 'header-0' },
//         { path: '/docs/referral-tasks/introduction', label: 'Introduction', headerId: 'header-2' },
//         { path: '/docs/referral-tasks/ref-manager-interface', label: 'Referral Manager Interface', headerId: 'header-3' },
//         { path: '/docs/referral-tasks/add-new-client-form', label: 'Add New Client Form', headerId: 'header-8' },
//         { path: '/docs/referral-tasks/summary', label: 'Summary of the Process', headerId: 'header-11' },
//         { path: '/docs/referral-tasks/user-profile', label: 'User Profile', headerId: 'header-12' },
//       ]
//     },
//   ]);

//   useEffect(() => {
//     const updatedMenuItems = menuItems.map((item, index) => {
//       const isSubMenuOpen = localStorage.getItem(`submenu-open-${index}`) === 'true';
//       const isActiveParent = location.pathname.startsWith(item.path);
//       return { ...item, isSubMenuOpen: isActiveParent || isSubMenuOpen };
//     });
//     setMenuItems(updatedMenuItems);
//   }, [location.pathname]);

//   useEffect(() => {
//     // Function to execute when the component mounts
//     const executeOnLoad = () => {
//       menuItems.forEach((item) => {
//         if (item.subItems) {
//           item.subItems.forEach((subItem) => {
//             if (location.pathname === subItem.path) {
//               handleHeaderClick(subItem.headerId, subItem.path);
//             }
//           });
//         }
//       });
//     };
  
//     // Timeout for delaying execution
//     const timer = setTimeout(() => {
//       executeOnLoad();
//     }, 2000); 
  
//     // Clean up timer on component unmount or when the dependencies change
//     return () => clearTimeout(timer);
  
//   }, [location.pathname, menuItems]);
  


//   return (
//     <>
//       <div className={`scroll-panel ${isOpen ? 'open' : ''}`}>
//         <ul>
//           {menuItems.map((item, index) => (
//             <li key={item.path} className={location.pathname.startsWith(item.path) ? 'active' : ''}>
//               <div
//                 onClick={() => item.path === '/docs/invoice-report' || item.path === '/docs/program-management' || item.path === '/docs/refferal-management' || item.path === '/docs/report-management' || item.path === '/docs/one-session' || item.path === '/docs/initial-config' || item.path === '/docs/front-desk' || item.path === '/docs/dashboard' || item.path === '/docs/subscription' || item.path === '/docs/client-management' || item.path === '/docs/drug-test' || item.path === '/docs/schedule' || item.path === '/docs/treatment-plan' || item.path === '/docs/check-in' || item.path === '/docs/billing' || item.path === '/docs/self-check-in' || item.path === '/docs/staff-management' || item.path === '/docs/refferal-management' || item.path === '/docs/configuration' || item.path === '/docs/forms-management' || item.path === '/docs/common-calendar' || item.path === '/docs/client-tasks' || item.path === '/docs/referral-tasks' ? handleInitialConfigurationClick(item, index) : handleItemClick(item, index)}
//               >
//                 <a className="text-dark font-weight-bold font-size-h6">
//                   {item.label}
//                 </a>
//                 {item.subItems && (
//                   <span className="arrow" style={{ float: 'right' }}>
//                     {item.isSubMenuOpen ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
//                   </span>
//                 )}
//               </div>
//               {item.subItems && item.isSubMenuOpen && (
//                 <ul className="submenu">
//                   {item.subItems.map((subItem, subIndex) => (
//                     <li
//                       style={{ paddingLeft: '5px', fontSize: '15px', color: 'silver'}}
//                       key={subItem.path}
//                       className={location.pathname === subItem.path ? 'active' : ''}
//                     >
//                       <a
//                         className="text-dark font-weight-bold font-size-h6"
//                         id={subItem.headerId}
//                         onClick={() => handleHeaderClick(subItem.headerId, subItem.path)}
//                         style={{ display: 'flex', alignItems: 'center' }}
//                       >
//                         <span style={{ display: 'flex', alignItems: 'center', color: 'silver' , justifyContent: 'center',  width: '20px', height: '20px', marginRight: '5px' }}>
//                           &#x25CF;
//                         </span>
//                         {subItem.label}
//                       </a>
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </li>
//           ))}
//         </ul>
//       </div>
//       <div className="scroll-panel-container">
//         <button className="toggle-button" onClick={togglePanel}>
//           {isOpen ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />}
//         </button>
//       </div>
//     </>
//   );
// };

// export default ScrollPanel;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './ScrollPanel.css';

const ScrollPanel = ({ onHeaderClick, currentHeaderId  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubMenu = (index) => {
    const updatedMenuItems = menuItems.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, isSubMenuOpen: !item.isSubMenuOpen };
        localStorage.setItem(`submenu-open-${i}`, updatedItem.isSubMenuOpen);
        return updatedItem;
      } else {
        localStorage.setItem(`submenu-open-${i}`, false);
        return { ...item, isSubMenuOpen: false };
      }
    });
    setMenuItems(updatedMenuItems);
  };

  const handleHeaderClick = (headerId, path) => {
    if (onHeaderClick) {
      onHeaderClick(headerId);
    }
    navigate(path);
  };

  const handleItemClick = (item, index) => {
    localStorage.clear();
    toggleSubMenu(index);

    if (!item.subItems) {
      console.log('Navigating to:', item.path);
      navigate(item.path);
    }
  };

  const handleInitialConfigurationClick = (item, index) => {
    localStorage.clear();
    console.log('Navigating to Initial Configuration:', item.path);
    toggleSubMenu(index);
    navigate(item.path);
  };

  const [menuItems, setMenuItems] = useState([
    {
      path: '/docs/initial-config',
      label: 'Initial Configuration',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/initial-config/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/initial-config/details', label: 'Details Section', headerId: 'header-1' },
        { path: '/docs/initial-config/company-info', label: 'Company Info', headerId: 'header-2' },
        { path: '/docs/initial-config/policy-role', label: 'Staff Policy and Role', headerId: 'header-3' },
        { path: '/docs/initial-config/session-location', label: 'Session Location and Type', headerId: 'header-4' },
        { path: '/docs/initial-config/bill-due', label: 'Bill Due Date and CPT Code Cost', headerId: 'header-5' },
        { path: '/docs/initial-config/drugtest-type', label: 'Drugtest Type and Method', headerId: 'header-6' },
        { path: '/docs/initial-config/form-group', label: 'Form Group Creation', headerId: 'header-7' },
        { path: '/docs/initial-config/event-type', label: 'Event Type in Scheduling Config', headerId: 'header-8' },
        { path: '/docs/initial-config/program-care', label: 'Program and Care Type', headerId: 'header-9' },
        { path: '/docs/initial-config/check-in-date', label: 'Check-in Date Flag', headerId: 'header-10' },
        { path: '/docs/initial-config/ref-source', label: 'Referral Source', headerId: 'header-11' },
        { path: '/docs/initial-config/payment-insurance', label: 'Payment and Insurance type', headerId: 'header-12' },
        { path: '/docs/initial-config/medication', label: 'Medication Type', headerId: 'header-22' },
        { path: '/docs/initial-config/new-staff', label: 'Create New Staff', headerId: 'header-23' },
        { path: '/docs/initial-config/contact-role', label: 'Staff Contact & Role', headerId: 'header-24' },
      ]
    },
    {
      path: '/docs/client-profile',
      label: 'Client Profile',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/client-profile/client-profile-page', label: 'Client Profile Page', headerId: 'header-0' },
        { path: '/docs/client-profile/action-buttons', label: 'Action Buttons', headerId: 'header-1' },
        { path: '/docs/client-profile/nav-tabs', label: 'Navigation Tabs', headerId: 'header-5' },
      ]
    },
    {
      path: '/docs/front-desk',
      label: 'Front Desk',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/front-desk/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/front-desk/header-section', label: 'Header Section', headerId: 'header-1' },
        { path: '/docs/front-desk/sidebar-navigation-menu', label: 'Sidebar Navigation Menu', headerId: 'header-2' },
        { path: '/docs/front-desk/main-content-area', label: 'Main Content Area', headerId: 'header-3' },
        { path: '/docs/front-desk/favorites-section', label: 'Favorites Section', headerId: 'header-4' },
        { path: '/docs/front-desk/user-profile-tab', label: 'User Profile Tab', headerId: 'header-5' },
        { path: '/docs/front-desk/floating-menu', label: 'Floating Menu', headerId: 'header-6' },
        { path: '/docs/front-desk/chat-window', label: 'Chat Window (Entryplus Intellibot Beta)', headerId: 'header-7' },
      ]
    },
    {
      path: '/docs/dashboard',
      label: 'Dashboard',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/dashboard/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/dashboard/pie-chart-and-key-metrics', label: 'Pie Chart and Key Metrics', headerId: 'header-1' },
        { path: '/docs/dashboard/bar-graph-and-summary-statistics', label: 'Bar Graph and Summary Statistics', headerId: 'header-2' },
      ]
    },
    {
      path: '/docs/subscription',
      label: 'Subscription',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/subscription/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/subscription/graph-explanation', label: 'Graph Explanation', headerId: 'header-1' },
        { path: '/docs/subscription/table-explanation', label: 'Table Explanation', headerId: 'header-3' },
      ]
    },
    {
      path: '/docs/client-management',
      label: 'Client Management',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/client-management/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/client-management/add-new-client', label: 'Adding a New Client', headerId: 'header-1' },
        { path: '/docs/client-management/managing-clients', label: 'Managing Clients', headerId: 'header-2' },
        { path: '/docs/client-management/detailed-tab-description', label: 'Detailed Tabs Description', headerId: 'header-5' },
        { path: '/docs/client-management/pre-registration-process', label: 'Client Pre-Registration Process', headerId: 'header-13' },
        { path: '/docs/client-management/client-status-change', label: 'Client Status Change', headerId: 'header-22' },
        { path: '/docs/client-management/assigning-staff', label: 'Assigning staff for a client', headerId: 'header-23' },
        { path: '/docs/client-management/add-programs', label: 'Add programs for a client', headerId: 'header-24' },
        { path: '/docs/client-management/change-level-of-care', label: 'Change the Level of Care', headerId: 'header-25' }
      ]
    },
    {
      path: '/docs/drug-test',
      label: 'Drug Test',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/drug-test/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/drug-test/add-new-drug-test', label: 'Adding a New Drug Test', headerId: 'header-1' }
      ]
    },
    {
      path: '/docs/one-session',
      label: 'One Session',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/one-session/form-session', label: 'Add Form Based Session', headerId: 'header-0' },
        { path: '/docs/one-session/interactive-session', label: 'Add Interactive Session', headerId: 'header-1' },
      ]
    },
    {
      path: '/docs/schedule',
      label: 'Schedule',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/schedule/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/schedule/viewing-schedules', label: 'Viewing Schedules', headerId: 'header-1' },
        { path: '/docs/schedule/creating-schedule', label: 'Creating a Schedule', headerId: 'header-4' },
        { path: '/docs/schedule/type-of-schedules', label: 'Types of Schedules', headerId: 'header-5' },
        { path: '/docs/schedule/appointment-status-update-for-client', label: 'Appointment Status Update for Client', headerId: 'header-12' },
        { path: '/docs/schedule/sharing-calendar', label: 'Sharing Calendar', headerId: 'header-13' },
        { path: '/docs/schedule/viewing-calendar-invites', label: 'Viewing Calendar Invites', headerId: 'header-14' },
        { path: "/docs/schedule/viewing-today's-schedule", label: "Viewing Today's Schedule", headerId: 'header-15' }
      ]
    },
    {
      path: '/docs/treatment-plan',
      label: 'Treatment Plan',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/treatment-plan/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/treatment-plan/treatment-plan-types', label: 'Treatment Plan Types', headerId: 'header-1' },
        { path: '/docs/treatment-plan/advanced-treatment-plan', label: 'Advanced Treatment Plan', headerId: 'header-2' },
        { path: '/docs/treatment-plan/add-new-advanced-treatment-plan', label: 'Adding a New Advanced Treatment Plan', headerId: 'header-6' },
        { path: '/docs/treatment-plan/standard-treatment-plan', label: 'Standard Treatment Plan', headerId: 'header-8' },
        { path: '/docs/treatment-plan/add-new-standard-treatment-plan', label: 'Adding a New Standard Treatment Plan', headerId: 'header-12' },
        { path: '/docs/treatment-plan/initial-treatment-plan', label: 'Initial Treatment Plan', headerId: 'header-14' },
        { path: '/docs/treatment-plan/add-new-initial-treatment-plan', label: 'Adding a New Initial Treatment Plan', headerId: 'header-18' },
        { path: '/docs/treatment-plan/treatment-plan-by-templates', label: 'Treatment Plan By Templates', headerId: 'header-20' },
        { path: '/docs/treatment-plan/add-new-treatment-plan-by-templates', label: 'Adding a New Treatment Plan by Template', headerId: 'header-24' }
      ]
    },
    {
      path: '/docs/check-in',
      label: 'Check-In',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/check-in/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/check-in/check-in-details-section', label: 'Check-In Details Section', headerId: 'header-1' },
        { path: '/docs/check-in/check-in-options', label: 'Check-In Options', headerId: 'header-2' },
        { path: '/docs/check-in/individual-check-in', label: 'Individual Check-In', headerId: 'header-3' },
        { path: '/docs/check-in/self-check-in', label: 'Self Check-In', headerId: 'header-4' },
        { path: '/docs/check-in/group-check-in', label: 'Group Check-In', headerId: 'header-5' }
      ]
    },
    {
      path: '/docs/billing',
      label: 'Billing',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/billing/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/billing/step-by-step-billing-process', label: 'Step-by-Step Billing Process', headerId: 'header-1' },
        { path: '/docs/billing/calculation-steps', label: 'Calculation Steps', headerId: 'header-2' },
        { path: '/docs/billing/example-calculation-1', label: 'Example Calculation 1', headerId: 'header-3' },
        { path: '/docs/billing/example-calculation-2', label: 'Example Calculation 2', headerId: 'header-5' }
      ]
    },
    {
      path: '/docs/invoice-report',
      label: 'Invoice Report',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/invoice-report/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/invoice-report/management', label: 'Invoice Management', headerId: 'header-1' },
        { path: '/docs/invoice-report/action', label: 'Actions on Invoices', headerId: 'header-2' },
        { path: '/docs/invoice-report/payment-status', label: 'Changing Payment Status', headerId: 'header-3' },
        { path: '/docs/invoice-report/advanced-search', label: 'Advanced Search', headerId: 'header-4' },
        { path: '/docs/invoice-report/add-bill', label: 'Add New Bill', headerId: 'header-6' },
        { path: '/docs/invoice-report/unarchive', label: 'Unarchive', headerId: 'header-8' }
      ]
    },
    {
      path: '/docs/self-check-in',
      label: 'Self Check-In',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/self-check-in/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/self-check-in/steps-for-self-check-in', label: 'Steps for Self Check-In', headerId: 'header-1' }
      ]
    },
    {
      path: '/docs/staff-management',
      label: 'Staff Management',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/staff-management/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/staff-management/staff-management-page', label: 'Staff Management Page', headerId: 'header-1' },
        { path: '/docs/staff-management/staff-details-table', label: 'Staff Details Table Columns', headerId: 'header-3' },
        { path: '/docs/staff-management/action-icons', label: 'Action Icons in the Tasks Column', headerId: 'header-4' },
        { path: '/docs/staff-management/staff-status', label: 'Staff Status', headerId: 'header-5' },
        { path: '/docs/staff-management/add-new-staff', label: 'How to add new staff', headerId: 'header-6' },
        { path: '/docs/staff-management/staff-info', label: 'Staff Information', headerId: 'header-7' },
        { path: '/docs/staff-management/staff-history', label: 'Staff History', headerId: 'header-8' },
        { path: '/docs/staff-management/mfa', label: 'MFA-Multi Factor Authentication', headerId: 'header-9' },
        { path: '/docs/staff-management/black-boxing-add', label: 'Black Boxing Clients(add)', headerId: 'header-10' },
        { path: '/docs/staff-management/black-boxing-remove', label: 'Black Boxing Clients(remove)', headerId: 'header-11' }
      ]
    },
    {
      path: '/docs/program-management',
      label: 'Program Management',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/program-management/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/program-management/components', label: 'Components', headerId: 'header-2' },
        { path: '/docs/program-management/categories', label: 'Categories', headerId: 'header-3' },
        { path: '/docs/program-management/client-info', label: 'Client Info Table', headerId: 'header-6' }
      ]
    },
    {
      path: '/docs/refferal-management',
      label: 'Referral Management',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/refferal-management/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/refferal-management/ref-page', label: 'Referral Management Page', headerId: 'header-1' },
        { path: '/docs/refferal-management/components', label: 'Components', headerId: 'header-2' },
        { path: '/docs/refferal-management/ref-details', label: 'Referral Details Table Columns', headerId: 'header-3' },
        { path: '/docs/refferal-management/action-icons', label: 'Action Icons in the Tasks Column', headerId: 'header-4' },
        { path: '/docs/refferal-management/add-ref', label: 'Add a New Referral', headerId: 'header-5' }
      ]
    },
    {
      path: '/docs/report-management',
      label: 'Report Management',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/report-management/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/report-management/medical', label: 'Medical Report', headerId: 'header-1' },
        { path: '/docs/report-management/general', label: 'General Report', headerId: 'header-3' },
        { path: '/docs/report-management/referral', label: 'Referral Report', headerId: 'header-5' },
        { path: '/docs/report-management/attendance', label: 'Attendance Report', headerId: 'header-7' },
        { path: '/docs/report-management/income', label: 'Income Report', headerId: 'header-9' },
        { path: '/docs/report-management/appointment', label: 'Appointment Report', headerId: 'header-11' },
        { path: '/docs/report-management/session', label: 'Session Report', headerId: 'header-13' },
        { path: '/docs/report-management/outstanding', label: 'Outstanding Report', headerId: 'header-15' },
        { path: '/docs/report-management/treatment', label: 'Treatment Plan Report', headerId: 'header-17' },
        { path: '/docs/report-management/client-progress', label: 'Client Progress Report', headerId: 'header-20' },
        { path: '/docs/report-management/client-status', label: 'Client Status Report', headerId: 'header-22' },
        { path: '/docs/report-management/absentees', label: 'Absentees Status Report', headerId: 'header-26' },
        { path: '/docs/report-management/staff-audit', label: 'Staff Audit', headerId: 'header-29' },
        { path: '/docs/report-management/weekly-business-audit', label: 'Weekly Business Audit', headerId: 'header-33' },
        { path: '/docs/report-management/weekly-counselor-audit', label: 'Weekly Counselor Audit', headerId: 'header-35' },
        { path: '/docs/report-management/custom', label: 'Custom Report', headerId: 'header-37' }
      ]
    },
    {
      path: '/docs/configuration',
      label: 'Configuration',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/configuration/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/configuration/system-setting', label: 'System Setting', headerId: 'header-2' },
        { path: '/docs/configuration/staff', label: 'Staff', headerId: 'header-5' },
        { path: '/docs/configuration/session', label: 'Session', headerId: 'header-9' },
        { path: '/docs/configuration/interactive-session', label: 'Interactive Session', headerId: 'header-14' },
        { path: '/docs/configuration/billing', label: 'Billing', headerId: 'header-17' },
        { path: '/docs/configuration/drug-test', label: 'Drug Test', headerId: 'header-20' },
        { path: '/docs/configuration/forms', label: 'Forms', headerId: 'header-23' },
        { path: '/docs/configuration/schedule', label: 'Schedule', headerId: 'header-25' },
        { path: '/docs/configuration/program', label: 'Program', headerId: 'header-28' },
        { path: '/docs/configuration/treatment-plan', label: 'Treatment Plan', headerId: 'header-31' },
        { path: '/docs/configuration/check-in', label: 'Check-In', headerId: 'header-35' },
        { path: '/docs/configuration/referral', label: 'Referral', headerId: 'header-38' },
        { path: '/docs/configuration/payment', label: 'Payment', headerId: 'header-40' },
        { path: '/docs/configuration/medication', label: 'Medication', headerId: 'header-44' },
        { path: '/docs/configuration/question-bank', label: 'Question Bank', headerId: 'header-46' }
      ]
    },
    {
      path: '/docs/forms-management',
      label: 'Forms Management',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/forms-management/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/forms-management/form-details', label: 'Form Details', headerId: 'header-1' },
        { path: '/docs/forms-management/add-new-form', label: 'Adding a New Form', headerId: 'header-4' },
        { path: '/docs/forms-management/add-new-group', label: 'Adding a New Group', headerId: 'header-5' }
      ]
    },
    {
      path: '/docs/common-calendar',
      label: 'Common Calendar',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/common-calendar/overview', label: 'Overview', headerId: 'header-0' },
        { path: '/docs/common-calendar/view-common-calendar', label: 'Viewing the Common Calendar', headerId: 'header-1' },
        { path: '/docs/common-calendar/create-schedule-in-common-calendar', label: 'Creating a Schedule in the Common Calendar', headerId: 'header-4' },
        { path: '/docs/common-calendar/view-schedule-details', label: 'Viewing Schedule Details', headerId: 'header-6' },
        { path: '/docs/common-calendar/print-schedule', label: 'Print Schedule', headerId: 'header-7' }
      ]
    },
    {
      path: '/docs/client-tasks',
      label: 'Client Login',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/client-tasks/login-process', label: 'Login Process', headerId: 'header-0' },
        { path: '/docs/client-tasks/introduction', label: 'Introduction', headerId: 'header-2' },
        { path: '/docs/client-tasks/profile-info', label: 'Profile Information', headerId: 'header-3' },
        { path: '/docs/client-tasks/form-registration', label: 'Form Registration', headerId: 'header-5' },
        { path: '/docs/client-tasks/guided-registration', label: 'Guided Registration', headerId: 'header-8' },
        { path: '/docs/client-tasks/check-in', label: 'Check-in', headerId: 'header-19' },
        { path: '/docs/client-tasks/assessment', label: 'Assessment', headerId: 'header-22' },
        { path: '/docs/client-tasks/user-profile', label: 'User Profile', headerId: 'header-23' },
      ]
    },
    {
      path: '/docs/referral-tasks',
      label: 'Referral Login',
      isSubMenuOpen: false,
      subItems: [
        { path: '/docs/referral-tasks/login-process', label: 'Login Process', headerId: 'header-0' },
        { path: '/docs/referral-tasks/introduction', label: 'Introduction', headerId: 'header-2' },
        { path: '/docs/referral-tasks/ref-manager-interface', label: 'Referral Manager Interface', headerId: 'header-3' },
        { path: '/docs/referral-tasks/add-new-client-form', label: 'Add New Client Form', headerId: 'header-8' },
        { path: '/docs/referral-tasks/summary', label: 'Summary of the Process', headerId: 'header-11' },
        { path: '/docs/referral-tasks/user-profile', label: 'User Profile', headerId: 'header-12' },
      ]
    },
  ]);

  useEffect(() => {
    const updatedMenuItems = menuItems.map((item, index) => {
      const isSubMenuOpen = localStorage.getItem(`submenu-open-${index}`) === 'true';
      const isActiveParent = location.pathname.startsWith(item.path);
      return { ...item, isSubMenuOpen: isActiveParent || isSubMenuOpen };
    });
    setMenuItems(updatedMenuItems);
  }, [location.pathname]);

  useEffect(() => {
    // Function to execute when the component mounts
    const executeOnLoad = () => {
      menuItems.forEach((item) => {
        if (item.subItems) {
          item.subItems.forEach((subItem) => {
            if (location.pathname === subItem.path) {
              handleHeaderClick(subItem.headerId, subItem.path);
            }
          });
        }
      });
    };
  
    // Timeout for delaying execution
    const timer = setTimeout(() => {
      executeOnLoad();
    }, 2000); 
  
    // Clean up timer on component unmount or when the dependencies change
    return () => clearTimeout(timer);
  
  }, [location.pathname, menuItems]);
  


  return (
    <>
      <div className={`scroll-panel ${isOpen ? 'open' : ''}`}>
        <ul>
          {menuItems.map((item, index) => (
            <li key={item.path} className={location.pathname.startsWith(item.path) ? 'active' : ''}>
              <div
                onClick={() => item.path === '/docs/invoice-report' || item.path === '/docs/program-management' || item.path === '/docs/refferal-management' || item.path === '/docs/report-management' || item.path === '/docs/one-session' || item.path === '/docs/initial-config' || item.path === '/docs/front-desk' || item.path === '/docs/dashboard' || item.path === '/docs/subscription' || item.path === '/docs/client-management' || item.path === '/docs/drug-test' || item.path === '/docs/schedule' || item.path === '/docs/treatment-plan' || item.path === '/docs/check-in' || item.path === '/docs/billing' || item.path === '/docs/self-check-in' || item.path === '/docs/staff-management' || item.path === '/docs/refferal-management' || item.path === '/docs/configuration' || item.path === '/docs/forms-management' || item.path === '/docs/common-calendar' || item.path === '/docs/client-tasks' || item.path === '/docs/referral-tasks' || item.path === '/docs/client-profile' ? handleInitialConfigurationClick(item, index) : handleItemClick(item, index)}
              >
                <a className="text-dark font-weight-bold font-size-h6">
                  {item.label}
                </a>
                {item.subItems && (
                  <span className="arrow" style={{ float: 'right' }}>
                    {item.isSubMenuOpen ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
                  </span>
                )}
              </div>
              {item.subItems && item.isSubMenuOpen && (
                <ul className="submenu">
                  {item.subItems.map((subItem) => (
                    <li
                      key={subItem.path}
                      className={currentHeaderId === subItem.headerId ? 'active' : ''} // Apply active class based on headerId
                      style={{ paddingLeft: '5px', fontSize: '15px', color: 'silver' }}
                    >
                      <a
                        className="text-dark font-weight-bold font-size-h6"
                        id={subItem.headerId}
                        onClick={() => handleHeaderClick(subItem.headerId, subItem.path)}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span style={{ display: 'flex', alignItems: 'center', color: 'silver', justifyContent: 'center', width: '20px', height: '20px', marginRight: '5px' }}>
                          &#x25CF;
                        </span>
                        {subItem.label}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="scroll-panel-container">
        <button className="toggle-button" onClick={togglePanel}>
          {isOpen ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />}
        </button>
      </div>
    </>
  );
};

export default ScrollPanel;
