// import { useState, useEffect } from 'react';
// import { s3, s3Config } from '../aws-config';
// import mammoth from 'mammoth';

// const useDocument = (fileKey) => {
//   const [document, setDocument] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchDocument = async () => {
//       const bucket = s3Config.bucketName;
//       try {
//         const params = { Bucket: bucket, Key: fileKey };
//         const objectData = await s3.getObject(params).promise();

//         const arrayBuffer = new Uint8Array(objectData.Body).buffer;
//         const result = await mammoth.convertToHtml({ arrayBuffer });

//         setDocument({ key: fileKey, content: result.value });
//       } catch (err) {
//         console.error('Error fetching document:', err);
//         setError('Failed to fetch document. Please try again later.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDocument();
//   }, [fileKey]);

//   return { document, error, loading };
// };

// export default useDocument;

import { useState, useEffect } from 'react';
import mammoth from 'mammoth';

const useDocument = (fileKey) => {
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocument = async () => {
      const bucketUrl = 'https://guided-document.s3.amazonaws.com/'; // Base URL for public S3 bucket
      const fileUrl = `${bucketUrl}${fileKey}`; // Full URL to access the document

      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const arrayBuffer = await response.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });

        setDocument({ key: fileKey, content: result.value });
      } catch (err) {
        console.error('Error fetching document:', err);
        setError('Failed to fetch document. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [fileKey]);

  return { document, error, loading };
};

export default useDocument;
