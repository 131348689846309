import React from 'react';
import  { useState, useEffect } from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    // const [imageUrl, setImageUrl] = useState("");

    // useEffect(() => {
    //   // Fetch signed URL from your backend
    //   const apiUrl =  process.env.REACT_APP_API_URL;
    //   fetch(`${apiUrl}/api/fetch_footer_logo`)
    //     .then((response) => response.json())
    //     .then((data) => setImageUrl(data.url))
    //     .catch((error) => console.error("Error fetching the signed URL", error));
    // }, []);
  
  return (
    <>
    <style>
    {`
    .gst-icon {
      width: 100px !important;
      background-color: white;
      border-radius: 5px !important;
    }
      h2 {
        padding-top: 0px !important;
        margin-top: 0px !important;
      }
  `}
      </style>
      <div className="pre-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <ul className="list-unstyled g-ul-li-tb-5--xs g-margin-b-30--xs">
                <li><a className="g-font-size-26--xs g-color--gray-lighter" href="index.php">Home</a></li>
                <li><a className="g-font-size-26--xs g-color--gray-lighter" href="about_us.php">About Us</a></li>
                <li><a className="g-font-size-26--xs g-color--gray-lighter" href="features.php">Features</a></li>
                <li><a className="g-font-size-26--xs g-color--gray-lighter" href="/docs">Docs</a></li>
              </ul>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2"></div>
            <div className="col-md-2"></div>
            <div className="col-md-4 col-sm-6 pre-footer-col">
              <h2>Our Contacts</h2>
              <address className="margin-bottom-40 g-font-size-26--xs">
                52 Glattly Drive,<br />
                Denville, NJ 07834,<br />
                USA<br />
                Phone: +1 (732)-507-5344<br />
                Email: <a href="mailto:info@entryplus.net">info@entryplus.net</a>
              </address>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <a href="https://entryplus.net/" style={{ textDecoration: 'none' }}>
                <img 
                  src='https://entryplus-site.s3.amazonaws.com/img/logos/gsticon.png'
                  className="gst-icon" 
                  alt="GST Icon" 
                />
              </a>
            </div>

            <div className="col-md-4 col-sm-4">
              <ul className="social-footer list-unstyled list-inline pull-center">
                <li><a href="https://www.facebook.com/The-great-saint-technologies-101359944892937/"><i className="fa fa-facebook"></i></a></li>
                <li><a href="https://www.linkedin.com/posts/entryplus_rehabilication-mentalhealth-mentalhealthsupport-activity-7234832440903483393-hqyW?utm_source=share&utm_medium=member_desktop"><i className="fa fa-linkedin"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UCaJPs3ihhVNXa4UwLuPsxIA?view_as=subscriber"><i className="fa fa-youtube"></i></a></li>
              </ul>
            </div>

            <div className="col-md-4 col-sm-4 padding-top-10 g-font-size-22--xs">
              Copyright © 2021-{currentYear}, The Great Saint Technologies
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const App = () => {
  return (
    <div>
      <Footer />
    </div>
  );
};

export default App;
