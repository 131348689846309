import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/corporate/css/themes/red.css';
import './assets/corporate/css/custom.css';
import './assets/corporate/css/custom_demo.css';
import './assets/corporate/css/global.css';
import './assets/corporate/css/style.css';
import './assets/corporate/css/style-responsive.css';
import './assets/plugins/font-awesome/css/font-awesome.min.css';
import './assets/plugins/bootstrap/css/bootstrap.min.css';
import './assets/pages/css/animate.css';
import './assets/plugins/fancybox/source/jquery.fancybox.css';
import './assets/plugins/owl.carousel/assets/owl.carousel.css';
import './assets/pages/css/components.css';
import './assets/pages/css/slider.css';
import Header from './components/Header';
import App from './App';
import Favicon from './Favi';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Favicon/>
      <Header/>
      <App />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
