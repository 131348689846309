
// export default App;
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import InfoPage from './components/InfoPage';

const App = () => (
  <Routes>
    <Route path="docs" element={<Home />} />
    <Route path="docs/initial-config" element={<InfoPage fileKey="Initial Configuration.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="details" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="company-info" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="policy-role" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="session-location" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="bill-due" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="drugtest-type" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="form-group" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="event-type" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="program-care" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="check-in-date" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="ref-source" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="payment-insurance" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="medication" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="new-staff" element={<InfoPage fileKey="Initial Configuration.docx" />} />
      <Route path="contact-role" element={<InfoPage fileKey="Initial Configuration.docx" />} />
    </Route>
    <Route path="docs/client-profile" element={<InfoPage fileKey="Client Profile Page.docx" />}>
      <Route path="client-profile-page" element={<InfoPage fileKey="Client Profile Page.docx" />} />
      <Route path="action-buttons" element={<InfoPage fileKey="Client Profile Page.docx" />} />
      <Route path="nav-tabs" element={<InfoPage fileKey="Client Profile Page.docx" />} />
    </Route>
    <Route path="docs/front-desk" element={<InfoPage fileKey="Front Desk.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Front Desk.docx" />} />
      <Route path="header-section" element={<InfoPage fileKey="Front Desk.docx" />} />
      <Route path="sidebar-navigation-menu" element={<InfoPage fileKey="Front Desk.docx" />} />
      <Route path="main-content-area" element={<InfoPage fileKey="Front Desk.docx" />} />
      <Route path="favorites-section" element={<InfoPage fileKey="Front Desk.docx" />} />
      <Route path="user-profile-tab" element={<InfoPage fileKey="Front Desk.docx" />} />
      <Route path="floating-menu" element={<InfoPage fileKey="Front Desk.docx" />} />
      <Route path="chat-window" element={<InfoPage fileKey="Front Desk.docx" />} />
    </Route>
    <Route path="docs/dashboard" element={<InfoPage fileKey="Dashboard.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Dashboard.docx" />} />
      <Route path="pie-chart-and-key-metrics" element={<InfoPage fileKey="Dashboard.docx" />} />
      <Route path="bar-graph-and-summary-statistics" element={<InfoPage fileKey="Dashboard.docx" />} />
    </Route>
    <Route path="docs/subscription" element={<InfoPage fileKey="Subscription.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Subscription.docx" />} />
      <Route path="graph-explanation" element={<InfoPage fileKey="Subscription.docx" />} />
      <Route path="table-explanation" element={<InfoPage fileKey="Subscription.docx" />} />
    </Route>
    <Route path="docs/client-management" element={<InfoPage fileKey="Client Management.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Client Management.docx" />} />
      <Route path="add-new-client" element={<InfoPage fileKey="Client Management.docx" />} />
      <Route path="managing-clients" element={<InfoPage fileKey="Client Management.docx" />} />
      <Route path="detailed-tab-description" element={<InfoPage fileKey="Client Management.docx" />} />
      <Route path="pre-registration-process" element={<InfoPage fileKey="Client Management.docx" />} />
      <Route path="client-status-change" element={<InfoPage fileKey="Client Management.docx" />} />
      <Route path="assigning-staff" element={<InfoPage fileKey="Client Management.docx" />} />
      <Route path="add-programs" element={<InfoPage fileKey="Client Management.docx" />} />
      <Route path="change-level-of-care" element={<InfoPage fileKey="Client Management.docx" />} />
    </Route>
    <Route path="docs/drug-test" element={<InfoPage fileKey="Drug Test.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Drug Test.docx" />} />
      <Route path="add-new-drug-test" element={<InfoPage fileKey="Drug Test.docx" />} />
    </Route>
    <Route path="docs/one-session" element={<InfoPage fileKey="One Session.docx" />}>
      <Route path="form-session" element={<InfoPage fileKey="One Session.docx" />} />
      <Route path="interactive-session" element={<InfoPage fileKey="One Session.docx" />} />
    </Route>
    <Route path="docs/schedule" element={<InfoPage fileKey="Schedule.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Schedule.docx" />} />
      <Route path="viewing-schedules" element={<InfoPage fileKey="Schedule.docx" />} />
      <Route path="creating-schedule" element={<InfoPage fileKey="Schedule.docx" />} />
      <Route path="type-of-schedules" element={<InfoPage fileKey="Schedule.docx" />} />
      <Route path="appointment-status-update-for-client" element={<InfoPage fileKey="Schedule.docx" />} />
      <Route path="sharing-calendar" element={<InfoPage fileKey="Schedule.docx" />} />
      <Route path="viewing-calendar-invites" element={<InfoPage fileKey="Schedule.docx" />} />
      <Route path="viewing-today's-schedule" element={<InfoPage fileKey="Schedule.docx" />} />
    </Route>
    <Route path="docs/treatment-plan" element={<InfoPage fileKey="Treatment Plan.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Treatment Plan.docx" />} />
      <Route path="treatment-plan-types" element={<InfoPage fileKey="Treatment Plan.docx" />} />
      <Route path="advanced-treatment-plan" element={<InfoPage fileKey="Treatment Plan.docx" />} />
      <Route path="add-new-advanced-treatment-plan" element={<InfoPage fileKey="Treatment Plan.docx" />} />
      <Route path="standard-treatment-plan" element={<InfoPage fileKey="Treatment Plan.docx" />} />
      <Route path="add-new-standard-treatment-plan" element={<InfoPage fileKey="Treatment Plan.docx" />} />
      <Route path="initial-treatment-plan" element={<InfoPage fileKey="Treatment Plan.docx" />} />
      <Route path="add-new-initial-treatment-plan" element={<InfoPage fileKey="Treatment Plan.docx" />} />
      <Route path="treatment-plan-by-templates" element={<InfoPage fileKey="Treatment Plan.docx" />} />
      <Route path="add-new-treatment-plan-by-templates" element={<InfoPage fileKey="Treatment Plan.docx" />} />
    </Route>
    <Route path="docs/check-in" element={<InfoPage fileKey="Check-In.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Check-In.docx" />} />
      <Route path="check-in-details-section" element={<InfoPage fileKey="Check-In.docx" />} />
      <Route path="check-in-options" element={<InfoPage fileKey="Check-In.docx" />} />
      <Route path="individual-check-in" element={<InfoPage fileKey="Check-In.docx" />} />
      <Route path="self-check-in" element={<InfoPage fileKey="Check-In.docx" />} />
      <Route path="group-check-in" element={<InfoPage fileKey="Check-In.docx" />} />
    </Route>
    <Route path="docs/billing" element={<InfoPage fileKey="Billing.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Billing.docx" />} />
      <Route path="step-by-step-billing-process" element={<InfoPage fileKey="Billing.docx" />} />
      <Route path="calculation-steps" element={<InfoPage fileKey="Billing.docx" />} />
      <Route path="example-calculation-1" element={<InfoPage fileKey="Billing.docx" />} />
      <Route path="example-calculation-2" element={<InfoPage fileKey="Billing.docx" />} />
    </Route>
    <Route path="docs/invoice-report" element={<InfoPage fileKey="Invoice Report.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Invoice Report.docx" />} />
      <Route path="management" element={<InfoPage fileKey="Invoice Report.docx" />} />
      <Route path="action" element={<InfoPage fileKey="Invoice Report.docx" />} />
      <Route path="payment-status" element={<InfoPage fileKey="Invoice Report.docx" />} />
      <Route path="advanced-search" element={<InfoPage fileKey="Invoice Report.docx" />} />
      <Route path="add-bill" element={<InfoPage fileKey="Invoice Report.docx" />} />
      <Route path="unarchive" element={<InfoPage fileKey="Invoice Report.docx" />} />
    </Route>
    <Route path="docs/self-check-in" element={<InfoPage fileKey="Self Check-In.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Self Check-In.docx" />} />
      <Route path="steps-for-self-check-in" element={<InfoPage fileKey="Self Check-In.docx" />} />
    </Route>
    <Route path="docs/staff-management" element={<InfoPage fileKey="Staff Management.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="staff-management-page" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="staff-details-table" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="action-icons" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="staff-status" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="add-new-staff" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="staff-info" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="staff-history" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="mfa" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="black-boxing-add" element={<InfoPage fileKey="Staff Management.docx" />} />
      <Route path="black-boxing-remove" element={<InfoPage fileKey="Staff Management.docx" />} />
    </Route>
    <Route path="docs/program-management" element={<InfoPage fileKey="Program Management.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Program Management.docx" />} />
      <Route path="components" element={<InfoPage fileKey="Program Management.docx" />} />
      <Route path="categories" element={<InfoPage fileKey="Program Management.docx" />} />
      <Route path="client-info" element={<InfoPage fileKey="Program Management.docx" />} />
    </Route>
    <Route path="docs/refferal-management" element={<InfoPage fileKey="Referral Management.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Referral Management.docx" />} />
      <Route path="ref-page" element={<InfoPage fileKey="Referral Management.docx" />} />
      <Route path="components" element={<InfoPage fileKey="Referral Management.docx" />} />
      <Route path="ref-details" element={<InfoPage fileKey="Referral Management.docx" />} />
      <Route path="action-icons" element={<InfoPage fileKey="Referral Management.docx" />} />
      <Route path="add-ref" element={<InfoPage fileKey="Referral Management.docx" />} />
    </Route>
    <Route path="docs/report-management" element={<InfoPage fileKey="Report Management.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="medical" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="general" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="referral" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="attendance" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="income" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="appointment" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="session" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="outstanding" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="treatment" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="client-progress" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="client-status" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="absentees" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="staff-audit" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="weekly-business-audit" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="weekly-counselor-audit" element={<InfoPage fileKey="Report Management.docx" />} />
      <Route path="custom" element={<InfoPage fileKey="Report Management.docx" />} />
    </Route>
    <Route path="docs/configuration" element={<InfoPage fileKey="Configuration.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="system-setting" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="staff" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="session" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="interactive-session" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="billing" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="drug-test" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="forms" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="schedule" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="program" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="treatment-plan" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="check-in" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="referral" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="payment" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="medication" element={<InfoPage fileKey="Configuration.docx" />} />
      <Route path="question-bank" element={<InfoPage fileKey="Configuration.docx" />} />
    </Route>
    <Route path="docs/forms-management" element={<InfoPage fileKey="Forms Management.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Forms Management.docx" />} />
      <Route path="form-details" element={<InfoPage fileKey="Forms Management.docx" />} />
      <Route path="add-new-form" element={<InfoPage fileKey="Forms Management.docx" />} />
      <Route path="add-new-group" element={<InfoPage fileKey="Forms Management.docx" />} />
    </Route>
    <Route path="docs/common-calendar" element={<InfoPage fileKey="Common Calendar.docx" />}>
      <Route path="overview" element={<InfoPage fileKey="Common Calendar.docx" />} />
      <Route path="view-common-calendar" element={<InfoPage fileKey="Common Calendar.docx" />} />
      <Route path="create-schedule-in-common-calendar" element={<InfoPage fileKey="Common Calendar.docx" />} />
      <Route path="view-schedule-details" element={<InfoPage fileKey="Common Calendar.docx" />} />
      <Route path="print-schedule" element={<InfoPage fileKey="Common Calendar.docx" />} />
    </Route>
    <Route path="docs/client-tasks" element={<InfoPage fileKey="Client Login-Client Tasks.docx" />}>
      <Route path="login-process" element={<InfoPage fileKey="Client Login-Client Tasks.docx" />} />
      <Route path="introduction" element={<InfoPage fileKey="Client Login-Client Tasks.docx" />} />
      <Route path="profile-info" element={<InfoPage fileKey="Client Login-Client Tasks.docx" />} />
      <Route path="form-registration" element={<InfoPage fileKey="Client Login-Client Tasks.docx" />} />
      <Route path="guided-registration" element={<InfoPage fileKey="Client Login-Client Tasks.docx" />} />
      <Route path="check-in" element={<InfoPage fileKey="Client Login-Client Tasks.docx" />} />
      <Route path="assessment" element={<InfoPage fileKey="Client Login-Client Tasks.docx" />} />
      <Route path="user-profile" element={<InfoPage fileKey="Client Login-Client Tasks.docx" />} />
    </Route>
    <Route path="docs/referral-tasks" element={<InfoPage fileKey="Referral Login-Referral Tasks.docx" />}>
      <Route path="login-process" element={<InfoPage fileKey="Referral Login-Referral Tasks.docx" />} />
      <Route path="introduction" element={<InfoPage fileKey="Referral Login-Referral Tasks.docx" />} />
      <Route path="ref-manager-interface" element={<InfoPage fileKey="Referral Login-Referral Tasks.docx" />} />
      <Route path="add-new-client-form" element={<InfoPage fileKey="Referral Login-Referral Tasks.docx" />} />
      <Route path="summary" element={<InfoPage fileKey="Referral Login-Referral Tasks.docx" />} />
      <Route path="user-profile" element={<InfoPage fileKey="Referral Login-Referral Tasks.docx" />} />
    </Route>
  </Routes>
);

export default App;
