import React from 'react';
import './ImageModal.css';

const ImageModal = ({ isOpen, imageSrc, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="image-modal" onClick={onClose}>
      <span className="close">&times;</span>
      <img className="modal-content" src={imageSrc} alt="Enlarged" style={{cursor: 'pointer'}}/>
    </div>
  );
};

export default ImageModal;
